<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M15.8334 3.33366H14.1667V2.50033C14.1667 2.27931 14.0789 2.06735 13.9226 1.91107C13.7663 1.75479 13.5544 1.66699 13.3334 1.66699C13.1123 1.66699 12.9004 1.75479 12.7441 1.91107C12.5878 2.06735 12.5 2.27931 12.5 2.50033V3.33366H7.50002V2.50033C7.50002 2.27931 7.41222 2.06735 7.25594 1.91107C7.09966 1.75479 6.8877 1.66699 6.66669 1.66699C6.44567 1.66699 6.23371 1.75479 6.07743 1.91107C5.92115 2.06735 5.83335 2.27931 5.83335 2.50033V3.33366H4.16669C3.50365 3.33366 2.86776 3.59705 2.39892 4.06589C1.93008 4.53473 1.66669 5.17062 1.66669 5.83366V15.8337C1.66669 16.4967 1.93008 17.1326 2.39892 17.6014C2.86776 18.0703 3.50365 18.3337 4.16669 18.3337H15.8334C16.4964 18.3337 17.1323 18.0703 17.6011 17.6014C18.07 17.1326 18.3334 16.4967 18.3334 15.8337V5.83366C18.3334 5.17062 18.07 4.53473 17.6011 4.06589C17.1323 3.59705 16.4964 3.33366 15.8334 3.33366ZM16.6667 15.8337C16.6667 16.0547 16.5789 16.2666 16.4226 16.4229C16.2663 16.5792 16.0544 16.667 15.8334 16.667H4.16669C3.94567 16.667 3.73371 16.5792 3.57743 16.4229C3.42115 16.2666 3.33335 16.0547 3.33335 15.8337V10.0003H16.6667V15.8337ZM16.6667 8.33366H3.33335V5.83366C3.33335 5.61265 3.42115 5.40068 3.57743 5.2444C3.73371 5.08812 3.94567 5.00033 4.16669 5.00033H5.83335V5.83366C5.83335 6.05467 5.92115 6.26663 6.07743 6.42291C6.23371 6.5792 6.44567 6.66699 6.66669 6.66699C6.8877 6.66699 7.09966 6.5792 7.25594 6.42291C7.41222 6.26663 7.50002 6.05467 7.50002 5.83366V5.00033H12.5V5.83366C12.5 6.05467 12.5878 6.26663 12.7441 6.42291C12.9004 6.5792 13.1123 6.66699 13.3334 6.66699C13.5544 6.66699 13.7663 6.5792 13.9226 6.42291C14.0789 6.26663 14.1667 6.05467 14.1667 5.83366V5.00033H15.8334C16.0544 5.00033 16.2663 5.08812 16.4226 5.2444C16.5789 5.40068 16.6667 5.61265 16.6667 5.83366V8.33366Z" fill="#828282"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>
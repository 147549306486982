<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M18.1667 8.33366C17.8334 7.33366 16.8334 6.66699 15.8334 6.66699C14.4167 6.66699 13.3334 7.75033 13.3334 9.16699C13.3334 10.2503 14 11.167 15 11.5003V12.917C15 15.0003 13.3334 16.667 11.25 16.667C9.16669 16.667 7.50002 15.0003 7.50002 12.917V12.417C9.91669 12.0003 11.6667 9.91699 11.6667 7.50033V2.50033C11.6667 2.00033 11.3334 1.66699 10.8334 1.66699H9.16669C8.66669 1.66699 8.33335 2.00033 8.33335 2.50033C8.33335 3.00033 8.66669 3.33366 9.16669 3.33366H10V7.50033C10 9.33366 8.50002 10.8337 6.66669 10.8337C4.83335 10.8337 3.33335 9.33366 3.33335 7.50033V3.33366H4.16669C4.66669 3.33366 5.00002 3.00033 5.00002 2.50033C5.00002 2.00033 4.66669 1.66699 4.16669 1.66699H2.50002C2.00002 1.66699 1.66669 2.00033 1.66669 2.50033V7.50033C1.66669 9.91699 3.41669 12.0003 5.83335 12.417V12.917C5.83335 15.917 8.25002 18.3337 11.25 18.3337C14.25 18.3337 16.6667 15.917 16.6667 12.917V11.5003C18 11.0837 18.6667 9.66699 18.1667 8.33366Z" fill="#828282"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>
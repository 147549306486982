<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M4.16666 15.0003H7.7C7.80967 15.0009 7.91839 14.9799 8.01992 14.9384C8.12145 14.8969 8.2138 14.8358 8.29166 14.7586L14.0583 8.98361L16.425 6.66694C16.5031 6.58947 16.5651 6.4973 16.6074 6.39576C16.6497 6.29421 16.6715 6.18528 16.6715 6.07527C16.6715 5.96526 16.6497 5.85634 16.6074 5.75479C16.5651 5.65324 16.5031 5.56108 16.425 5.48361L12.8917 1.90861C12.8142 1.8305 12.722 1.76851 12.6205 1.7262C12.5189 1.68389 12.41 1.66211 12.3 1.66211C12.19 1.66211 12.0811 1.68389 11.9795 1.7262C11.878 1.76851 11.7858 1.8305 11.7083 1.90861L9.35833 4.26694L3.575 10.0419C3.49776 10.1198 3.43666 10.2122 3.39519 10.3137C3.35372 10.4152 3.3327 10.5239 3.33333 10.6336V14.1669C3.33333 14.388 3.42113 14.5999 3.57741 14.7562C3.73369 14.9125 3.94565 15.0003 4.16666 15.0003ZM12.3 3.67527L14.6583 6.03361L13.475 7.21694L11.1167 4.85861L12.3 3.67527ZM5 10.9753L9.94166 6.03361L12.3 8.39194L7.35833 13.3336H5V10.9753ZM17.5 16.6669H2.5C2.27898 16.6669 2.06702 16.7547 1.91074 16.911C1.75446 17.0673 1.66666 17.2793 1.66666 17.5003C1.66666 17.7213 1.75446 17.9333 1.91074 18.0895C2.06702 18.2458 2.27898 18.3336 2.5 18.3336H17.5C17.721 18.3336 17.933 18.2458 18.0893 18.0895C18.2455 17.9333 18.3333 17.7213 18.3333 17.5003C18.3333 17.2793 18.2455 17.0673 18.0893 16.911C17.933 16.7547 17.721 16.6669 17.5 16.6669Z" fill="#BDBDBD"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>
<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M17.5 10.0003C17.279 10.0003 17.067 10.0881 16.9108 10.2444C16.7545 10.4006 16.6667 10.6126 16.6667 10.8336V15.8336C16.6667 16.0546 16.5789 16.2666 16.4226 16.4229C16.2663 16.5791 16.0544 16.6669 15.8334 16.6669H4.16669C3.94567 16.6669 3.73371 16.5791 3.57743 16.4229C3.42115 16.2666 3.33335 16.0546 3.33335 15.8336V4.16694C3.33335 3.94593 3.42115 3.73397 3.57743 3.57769C3.73371 3.42141 3.94567 3.33361 4.16669 3.33361H9.16669C9.3877 3.33361 9.59966 3.24581 9.75594 3.08953C9.91222 2.93325 10 2.72129 10 2.50027C10 2.27926 9.91222 2.0673 9.75594 1.91102C9.59966 1.75474 9.3877 1.66694 9.16669 1.66694H4.16669C3.50365 1.66694 2.86776 1.93033 2.39892 2.39917C1.93008 2.86802 1.66669 3.5039 1.66669 4.16694V15.8336C1.66669 16.4966 1.93008 17.1325 2.39892 17.6014C2.86776 18.0702 3.50365 18.3336 4.16669 18.3336H15.8334C16.4964 18.3336 17.1323 18.0702 17.6011 17.6014C18.07 17.1325 18.3334 16.4966 18.3334 15.8336V10.8336C18.3334 10.6126 18.2456 10.4006 18.0893 10.2444C17.933 10.0881 17.721 10.0003 17.5 10.0003ZM5.00002 10.6336V14.1669C5.00002 14.388 5.08782 14.5999 5.2441 14.7562C5.40038 14.9125 5.61234 15.0003 5.83335 15.0003H9.36669C9.47636 15.0009 9.58508 14.9799 9.68661 14.9384C9.78814 14.8969 9.88049 14.8358 9.95835 14.7586L15.725 8.98361L18.0917 6.66694C18.1698 6.58947 18.2318 6.4973 18.2741 6.39576C18.3164 6.29421 18.3382 6.18528 18.3382 6.07527C18.3382 5.96526 18.3164 5.85634 18.2741 5.75479C18.2318 5.65324 18.1698 5.56108 18.0917 5.48361L14.5584 1.90861C14.4809 1.8305 14.3887 1.76851 14.2872 1.7262C14.1856 1.68389 14.0767 1.66211 13.9667 1.66211C13.8567 1.66211 13.7478 1.68389 13.6462 1.7262C13.5447 1.76851 13.4525 1.8305 13.375 1.90861L11.025 4.26694L5.24169 10.0419C5.16445 10.1198 5.10335 10.2122 5.06188 10.3137C5.02041 10.4152 4.99939 10.5239 5.00002 10.6336ZM13.9667 3.67527L16.325 6.03361L15.1417 7.21694L12.7834 4.85861L13.9667 3.67527ZM6.66669 10.9753L11.6084 6.03361L13.9667 8.39194L9.02502 13.3336H6.66669V10.9753Z" fill="#2F80ED"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if(props.fill){
                return props.fill
            }
            
            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>
<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M12.5 9.16699H10.8334V5.83366C10.8334 5.61265 10.7456 5.40068 10.5893 5.2444C10.433 5.08812 10.221 5.00033 10 5.00033C9.77901 5.00033 9.56705 5.08812 9.41077 5.2444C9.25449 5.40068 9.16669 5.61265 9.16669 5.83366V10.0003C9.16669 10.2213 9.25449 10.4333 9.41077 10.5896C9.56705 10.7459 9.77901 10.8337 10 10.8337H12.5C12.721 10.8337 12.933 10.7459 13.0893 10.5896C13.2456 10.4333 13.3334 10.2213 13.3334 10.0003C13.3334 9.77931 13.2456 9.56735 13.0893 9.41107C12.933 9.25479 12.721 9.16699 12.5 9.16699ZM10 1.66699C8.35185 1.66699 6.74068 2.15573 5.37027 3.07141C3.99986 3.98709 2.93176 5.28858 2.30103 6.8113C1.6703 8.33401 1.50527 10.0096 1.82681 11.6261C2.14836 13.2426 2.94203 14.7274 4.10747 15.8929C5.27291 17.0583 6.75776 17.852 8.37427 18.1735C9.99078 18.4951 11.6663 18.3301 13.1891 17.6993C14.7118 17.0686 16.0133 16.0005 16.9289 14.6301C17.8446 13.2597 18.3334 11.6485 18.3334 10.0003C18.3334 8.90598 18.1178 7.82234 17.699 6.8113C17.2802 5.80025 16.6664 4.88159 15.8926 4.10777C15.1188 3.33395 14.2001 2.72012 13.1891 2.30133C12.178 1.88254 11.0944 1.66699 10 1.66699ZM10 16.667C8.68148 16.667 7.39255 16.276 6.29622 15.5435C5.19989 14.8109 4.34541 13.7697 3.84083 12.5515C3.33624 11.3334 3.20422 9.99293 3.46146 8.69972C3.71869 7.40652 4.35363 6.21863 5.28598 5.28628C6.21833 4.35393 7.40622 3.71899 8.69942 3.46176C9.99263 3.20452 11.3331 3.33654 12.5512 3.84113C13.7694 4.34571 14.8106 5.2002 15.5432 6.29652C16.2757 7.39285 16.6667 8.68178 16.6667 10.0003C16.6667 11.7684 15.9643 13.4641 14.7141 14.7144C13.4638 15.9646 11.7681 16.667 10 16.667Z" fill="#828282"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>